<template>
    <!--<v-container fluid class="home" fill-height style="max-height: 100vh;">-->
    <v-container fluid class="bonus-background">
        <paypin-form v-if="param" :param="param"></paypin-form>
        <paypin-list v-else :paramPagination="true" :paramFilter="true" :paramTitle="true"/>
    </v-container>
</template>

<script>
  import PaypinList from '@/components/PaypinList';
  import PaypinForm from "../components/PaypinForm";

  export default {
    name: 'Paypins',
//    props: {holderType: String},
    props: ['param'],
    components: {
      PaypinForm,
      PaypinList
    },
    mounted() {
      //console.log(this.$route);
      //console.log(this.param);
    },
    methods: {
    }
  };
</script>

<style scoped>
</style>
